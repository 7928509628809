export default function uikitMagics(Alpine) {
    // Carousel (tiny-slider.js)
    Alpine.magic("carousel", () => async () => {
        const { tns } = await import("tiny-slider/dist/tiny-slider");
        return tns;
    });
    // Tooltip (tippy.js)
    Alpine.magic("tooltip", () => async () => {
        const { default: tippy } = await import("tippy.js");
        return tippy;
    });
}