import Alpine from "alpinejs";
import AsyncAlpine from "async-alpine";
import mask from "@alpinejs/mask";
import intersect from "@alpinejs/intersect";
import persist from "@alpinejs/persist";
import focus from '@alpinejs/focus'
import uikitMagics from "../../../../../sanofi-lm-uikit/themes/uikit/src/js/main.magics";

import "./magics";
import "./directives";

import "./modules/lazysizes";
import "./modules/track";

window.Alpine = Alpine;
Alpine.plugin(intersect);
Alpine.plugin(mask);
Alpine.plugin(focus);
Alpine.plugin(persist);
Alpine.plugin(uikitMagics);

document.addEventListener("alpine:initialized", () => {
  window.Alpine.isInitialized = true;
});

export { Alpine, AsyncAlpine };
